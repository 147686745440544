import React from 'react';
import clsx from 'clsx';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faVoicemail,
  faFax,
  faSms,
  faAddressBook,
  // faCloud,
} from '@fortawesome/free-solid-svg-icons';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from '@material-ui/core/Drawer';
// import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@material-ui/icons/Help';
import PhoneIcon from '@material-ui/icons/Phone';
// import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotificationMenu from './notificationMenu';
import Avatar from '@material-ui/core/Avatar';
// import DialpadIcon from '@material-ui/icons/Dialpad';
// import HelpMenu from './helpMenu';
import { connect } from 'react-redux';
import { setOpenDialer, setRemoteNumber } from '../store/features/user/userSlice';
import { setIssueForm } from "../store/features/issueForm/issueFormSlice";
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { WebRtcContextJsSIP } from '../index';

const mapDispatchToProps = {
  setOpenDialer,
  setRemoteNumber,
  setIssueForm,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'block',
    fontFamily: 'Y2K Neophyte',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(3),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    maxHeight: 'calc(100vh)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '0px',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9) + 1,
    },
  },
  listItems: {
    marginTop: theme.spacing(2),
    '&.Mui-selected': {
      backgroundColor: 'unset',
      '& .MuiListItemIcon-root, .MuiListItemText-root span': {
        color: theme.palette.primary.light,
      },
    },
  },
  listItemIcon: {
    paddingLeft: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: 'flex',
    '&>button:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  settings: {
    marginTop: 'auto',
    marginBottom: theme.spacing(2),
  },
  faResize: {
    width: '1.5rem !important',
    fontSize: '1.4142857142857144rem',
  },
  avatar: {
    fontSize: '0.7em',
  },
  avatarDot: {
    position: 'absolute',
    bottom: '6px',
    right: '8px',
  },
  connected: {
    '& circle': { // This works
      opacity: '1.0',
      color: '#3d9c4a',
    }
  },
  disconnected: {
    '& circle': { // This works
      opacity: '1.0',
      color: '#ff3636',
    }
  },
  dialPad1Container: {
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
    width: 32,
    height: 32,
  },
  dialPad1: {
    color: theme.palette.common.black,
  },
  dialPad2Container: {
    background: '#ff4a4a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
    width: 32,
    height: 32,
  },
  dialPad2: {
    color: theme.palette.common.white,
  },
  support: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mainMenuHeader: {
    borderBottom: '1px solid ' + theme.palette.primary.hr,
    paddingBottom: '8px',
    marginBottom: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  mainMenuLogout: {
    borderTop: '1px solid ' + theme.palette.primary.hr,
    paddingTop: '8px',
    marginTop: '8px',
  },
  mainMenuItemIcon: {
    minWidth: '40px',
    color: theme.palette.primary.light,
  }
}));

const MCLAppBar = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  let { path } = useRouteMatch()
  let location = useLocation()
  const {
    logoutFn,
    history,
    userPhoto,
    branding,
    user,
    phonebook,
    setOpenDialer,
    setRemoteNumber,
    setIssueForm,
  } = props

  const [open, setOpen] = React.useState(false)
  const [notificationCount, setNotificationCount] = React.useState(0)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [callSessionAnchorEl, setCallSessionAnchorEl] = React.useState(null)
  const [notifcationsAnchorEl, setNotifcationsAnchorEl] = React.useState(null)

  const isProfileMenuOpen = Boolean(anchorEl)
  const isCallSessionMenuOpen = Boolean(callSessionAnchorEl)
  const isNotificationMenuOpen = Boolean(notifcationsAnchorEl)
  const webRtcContextJsSIP = React.useContext(WebRtcContextJsSIP)
  const [sipRegistered, setSipRegistered] = React.useState(false)

  const sipRegisterCb = () => {
    setSipRegistered(webRtcContextJsSIP.getSipRegistered())
  }

  if (user.voip.active === 'yes') {
    webRtcContextJsSIP.setNotifyOnRegister(sipRegisterCb)
  }

  const handleMenuOpen = (menu, event) => {
    if (menu.toLowerCase() === 'account') {
      setAnchorEl(event.currentTarget)
    } else if (menu.toLowerCase() === 'callsession') {
      setCallSessionAnchorEl(event.currentTarget)
    } else {
      setNotifcationsAnchorEl(event.currentTarget)
    }
  };

  const handleMenuClose = (menu) => {
    if (menu.toLowerCase() === 'account') {
      setAnchorEl(null)
    } else if (menu.toLowerCase() === 'callsession') {
      setCallSessionAnchorEl(null)
    } else {
      setNotifcationsAnchorEl(null)
    }
  };

  const handleLogout = () => {
    handleMenuClose('account');
    logoutFn();
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const renderProfileMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='account-menu'
      keepMounted
      open={isProfileMenuOpen}
      onClose={(e) => handleMenuClose('account')}
    // PaperProps={{
    //   style: {
    //     minWidth: '300px'
    //   }
    // }}
    >
      <ListItem className={classes.mainMenuHeader}>
        <div style={{ fontWeight: 500, fontSize: 20, paddingBottom: '4px' }}>
          {`${user.firstName} ${user.lastName}`}
        </div>
        <div style={{ fontWeight: 400, fontSize: 14 }}>
          {`Extension ${user.voicemail.mailbox}`}
        </div>
      </ListItem>
      <MenuItem
        component={Link}
        to={`/dashboard/user/profile`}
        onClick={(e) => handleMenuClose('account')}
      >
        <ListItemIcon className={classes.mainMenuItemIcon}>
          <PersonOutlineIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='My Profile' />
      </MenuItem>
      {/* <MenuItem
          disabled={true}
          component={Link}
          to={`/dashboard/user/account`}
          onClick={(e) => handleMenuClose('account')}
        >
          My Account
        </MenuItem> */}
      <MenuItem
        component={Link}
        to={`/dashboard/settings`}
        onClick={(e) => handleMenuClose('account')}
      >
        <ListItemIcon className={classes.mainMenuItemIcon}>
          <SettingsOutlinedIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleMenuClose('account')
          setIssueForm({ enabled: true })
        }}
      >
        <ListItemIcon className={classes.mainMenuItemIcon}>
          <ReportProblemOutlinedIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Feedback' />
      </MenuItem>
      <MenuItem
        onClick={(e) => handleLogout(e)}
        className={classes.mainMenuLogout}>
        <ListItemIcon className={classes.mainMenuItemIcon}>
          <ExitToAppIcon fontSize='small' style={{ transform: 'rotate(180deg)' }} />
        </ListItemIcon>
        <ListItemText primary='Sign Out' />
      </MenuItem>
    </Menu>
  );

  const renderCallSessionMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={callSessionAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='callSession-menu'
      keepMounted
      open={isCallSessionMenuOpen}
      onClose={(e) => handleMenuClose('callSession')}
    // PaperProps={{
    //   style: {
    //     minWidth: '300px'
    //   }
    // }}
    >
      {webRtcContextJsSIP.getRemoteNumbers().map(remoteNumber => {
        const contact = phonebook.find((e) => e.voicemail.mailbox === remoteNumber)
        const contactName = (contact) ? contact.firstName + ' ' + contact.lastName : remoteNumber
        return <MenuItem
          key={remoteNumber}
          onClick={(e) => {
            setRemoteNumber(remoteNumber)
            setOpenDialer(!user.openDialer)
            handleMenuClose('callSession')
          }}
        >
          <ListItemText key={'li_' + remoteNumber} primary={contactName} />
        </MenuItem>
      })}
    </Menu>
  );

  const makeDrawerMenu = () => {
    const drawerMenu = [
      {
        text: 'Calls',
        icon: <PhoneIcon />,
        link: '/call/phone/all',
      },
      {
        text: 'Voicemail',
        icon: <FontAwesomeIcon icon={faVoicemail} className={classes.faResize} />,
        link: '/voice/mail/all',
      },
      {
        text: 'Fax',
        icon: <FontAwesomeIcon icon={faFax} className={classes.faResize} />,
        link: '/fax/fax/all',
      },
      {
        text: 'Contacts',
        icon: <FontAwesomeIcon icon={faAddressBook} className={classes.faResize} />,
        link: '/contacts/all',
      },
      {
        text: 'Conference',
        icon: <FontAwesomeIcon icon={faUsers} className={classes.faResize} />,
        link: '/conference/personal',
      },
      {
        text: 'SMS',
        icon: <FontAwesomeIcon icon={faSms} className={classes.faResize} />,
        link: '/sms/sms/all',
      },
      // {
      //   text: 'Cloud Storage',
      //   icon: (
      //     <FontAwesomeIcon icon={faCloud} className={classes.faResize} />
      //   ),
      //   link: '/cloud',
      // },
    ];

    return drawerMenu.map((item) => (
      <ListItem
        button
        component={Link}
        to={`${path}${item.link}`}
        onClick={() => setOpen(false)}
        selected={
          location.pathname.indexOf(item.link.split('/')[1]) !== -1 &&
          location.pathname.indexOf('notifications') === -1
        }
        key={item.text}
        className={classes.listItems}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItem>
    ));
  }

  // const handleCallSessionIconClick = (e) => {
  //   const remoteNumbers = webRtcContextJsSIP.getRemoteNumbers()
  //   if (webRtcContextJsSIP.getInConference() || remoteNumbers.length === 1) {
  //     setRemoteNumber(remoteNumbers[0])
  //     setOpenDialer(!user.openDialer)
  //   } else {
  //     handleMenuOpen('callSession', e)
  //   }
  // }

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            edge='start'
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant='h6' noWrap>
            {branding.companyName}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label='show new notifications'
              aria-controls='notification-menu'
              aria-haspopup='true'
              onClick={(e) => handleMenuOpen('notifications', e)}
              color='inherit'
            >
              <Badge
                max={999}
                badgeContent={
                  notificationCount > 0 ? notificationCount : null
                }
                color='secondary'
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {/* <IconButton
              onClick={(e) => user.activeCall && handleCallSessionIconClick(e)}>
              <div className={user.activeCall ? classes.dialPad2Container : classes.dialPad1Container}>
                <DialpadIcon className={user.activeCall ? classes.dialPad2 : classes.dialPad1} />
              </div>
            </IconButton> */}
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls='account-menu'
              aria-haspopup='true'
              onClick={(e) => handleMenuOpen('account', e)}
              color='inherit'
            >
              <Avatar className={classes.avatar} src={userPhoto}>
                {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
              </Avatar>
              {user.voip.active === 'yes' ?
                <CircleTwoToneIcon
                  className={classes.avatarDot + ' ' + (sipRegistered ? classes.connected : classes.disconnected)}
                  fontSize='small'
                  primary='white'
                /> :
                null
              }
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{makeDrawerMenu()}</List>
        <List className={classes.settings}>
          <ListItem
            button
            className={clsx(classes.listItems, classes.support)}
            onClick={() => setOpen(false)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary='Support' />
          </ListItem>
          {/* <ListItem
              button
              className={classes.listItems}
              component={Link}
              to={`${path}/settings`}
              onClick={() => setOpen(false)}
              selected={
                location.pathname.indexOf('settings') !== -1
              }
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Global Settings' />
            </ListItem> */}
        </List>
      </Drawer>
      {renderProfileMenu}
      {renderCallSessionMenu}

      <NotificationMenu
        isOpen={isNotificationMenuOpen}
        onCloseFunction={handleMenuClose}
        menuAnchorElement={notifcationsAnchorEl}
        badgeSetter={setNotificationCount}
        history={history}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    user: state.user,
    phonebook: state.phonebook,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MCLAppBar);