import { createSlice } from "@reduxjs/toolkit";

const RecordingSlice = createSlice({
  name: "recordings",
  initialState: [],
  reducers: {
    setRecordings(state, action) {
      let recordings = action.payload.callRecordings;

      return recordings;
    },
  },
});

export const { setRecordings } = RecordingSlice.actions;
export default RecordingSlice.reducer;
