import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route, useLocation } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import { setError } from "../store/features/error/errorSlice";
import Drawer from '@material-ui/core/Drawer';
import { 
  setActiveCall,
  setSipSessions,
  setStartConference,
  setCallingNumber,
  setCalling,
  setSipCalling,
} from "../store/features/user/userSlice";
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CallJournal from "../components/callJournal";
import Recording from "../components/recording";
import Phone from "../components/Phone";
import {
  renderDrawerMenu,
  sipUseEffect,
  webRtcNotifyCallback,
  getPhoneBookEntry,
  sipDialClickNew,
} from "../utils/common";
import {
  initiateCall,
  urldecode,
  sanitizePhoneNumber,
} from "../utils/utils";
import { useStyles } from "./styles/viewStyles";
import HelpMenu from "../components/helpMenu";
import { WebRtcContextJsSIP } from "../index";

const mapDispatchToProps = {
  setError,
  setActiveCall,
  setCallingNumber,
  setSipSessions,
  setStartConference,
  setCalling,
  setSipCalling,
};

const CallView = (props) => {
  const classes = useStyles();
  const {
    session,
    phonebook,
    match,
    history,
    setError,
    setActiveCall,
    setSipSessions,
    setStartConference,
    startConference,
    callingNumber,
    calling,
    sipCalling,
    setCallingNumber,
    setCalling,
    setSipCalling,
    voip,
    autoDial,
  } = props;
  let {
    remoteNumber,
  } = props
  const tab = match.params.type;
  const isCurrentTab = (item) => tab.toLowerCase() === item.type.toLowerCase();
  const [mobileOpen, setMobileOpen] = useState(false);
  const options = ["Office", "Mobile", "Custom"];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [deviceSelection, setDeviceSelection] = useState(options[selectedIndex]);
  const [sipMode, setSipMode] = useState("newSipCall");

  const webRtcContextJsSIP = React.useContext(WebRtcContextJsSIP);

  const numberOfSessions = webRtcContextJsSIP.getNumberOfSessions() 
  if (numberOfSessions === 1) {
    remoteNumber = webRtcContextJsSIP.getRemoteNumbers()[0]
  } else if (numberOfSessions === 0) {
    remoteNumber = callingNumber
  }
  const [value, setValue] = useState(!remoteNumber || remoteNumber === 0
    ? null
    : remoteNumber.hasOwnProperty("voicemail") 
      ? remoteNumber
      : getPhoneBookEntry(phonebook, remoteNumber));
  const [viewValue, setViewValue] = useState(value);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialClick = () => {
    if (value) {
      setCalling(true);
    }
  };

  var controller = new AbortController();
  var signal = controller.signal;

  useEffect(() => {
    if (value && calling) {
      let values = {
        token: session.token,
        server: session.lastServer,
        calledDevice: value.hasOwnProperty("preferredDeviceNumbers")
          ? value.hasOwnProperty("callNumber")
            ? value.callNumber
            : value.preferredDeviceNumbers.office
          : value,
        preferredDevice: deviceSelection.toLowerCase(),
      };

      async function makeCall() {
        try {
          const call = await initiateCall(values, signal);

          if (
            typeof call === "object" &&
            call.result.toLowerCase() === "success"
          ) {
            setError({
              visible: true,
              type: "success",
              message: "Success: Call Connected!",
            });
            setCalling(false);
          } else if (typeof call === "object") {
            throw new Error(call.text);
          } else {
            setError({
              visible: true,
              type: "warning",
              message: call,
            });
            setCalling(false);
          }
        } catch (err) {
          setError({
            visible: true,
            type: "error",
            message: err,
          });
          setCalling(false);
        }
      }
      makeCall();
    }
    // eslint-disable-next-line
  }, [calling])

  const notifyCB = webRtcNotifyCallback(phonebook, setCallingNumber, setSipMode, setSipCalling, webRtcContextJsSIP, setActiveCall, setSipSessions, history, setError)
  webRtcContextJsSIP.setNotifyCB(notifyCB);

  const handleSipDialClick = sipDialClickNew(value, setCallingNumber, webRtcContextJsSIP, setSipMode, sipCalling, setSipCalling, setActiveCall, startConference, setStartConference, phonebook)

  useEffect(() => {
    if (value && !value.hasOwnProperty("voicemail")) {
      setValue(getPhoneBookEntry(phonebook, value))
    }
    sipUseEffect(value, sipCalling, sipMode, webRtcContextJsSIP, setActiveCall, startConference, setStartConference)
    // eslint-disable-next-line
  }, [sipCalling])

  const handleProtocolHandler = (launchParams) => {
    // Use URL object for robust parsing
    const pathname = new URL(launchParams.targetURL).pathname;
    history.push(pathname)
  }

  useEffect(() => {
    if (typeof window !== "undefined" && window.launchQueue) {
      window.launchQueue.setConsumer(handleProtocolHandler);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const drawerMenuItems = [
    {
      text: "Phone",
      type: "phone",
      to: "phone/all"
    },
    {
      text: "Call Journal",
      type: "journal",
      to: "journal/all"
    },
    {
      text: "Call Recordings",
      type: "recording",
      to: "recording/all"
    },
  ];

  const drawerMenu = renderDrawerMenu(
    classes,
    drawerMenuItems,
    isCurrentTab,
    handleDrawerToggle,
    '/dashboard/call/'
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const journalMenu = [
    {
      title: "All",
      linkId: "all",
    },
    {
      title: "Missed",
      linkId: "missed",
    },
    {
      title: "Incoming",
      linkId: "incoming",
    },
    {
      title: "Outgoing",
      linkId: "outgoing",
    },
    {
      title: "Redirected",
      linkId: "",
    },
  ];

  const recordingMenu = [
    {
      title: "All",
      linkId: "all",
    },
    {
      title: "New",
      linkId: "new",
    },
    {
      title: "Viewed",
      linkId: "viewed",
    },
    {
      title: "Urgent",
      linkId: "urgent",
    },
    {
      title: "Deleted",
      linkId: "deleted",
    },
  ];

  // handle protocol handler redirects
  // parse the tel url argument and initiate a call
  const location = useLocation();
  const url = location.pathname; // Get pathname from location

  const parts = url.split('/');
  const telIndex = parts.indexOf('tel');

  if (telIndex !== -1 && telIndex === parts.length - 2) {
    try {
      const protocolTelParam = parts[telIndex + 1];
      const protocolTelParamDecoded = urldecode(protocolTelParam)
      const phoneNumber = sanitizePhoneNumber(protocolTelParamDecoded.split(':')[1]) //.replace(/[^0-9+]/g, '').replace(/\+/g, '00');
      // Handle the extracted phone number
      if (phoneNumber !== viewValue) {
        // const entry = getPhoneBookEntry(phonebook, phoneNumber)
        setViewValue(phoneNumber)
      }
      history.push("/dashboard/call/phone/all")
      if (autoDial === "yes") {
        setSipCalling(true)
      }
    }
    catch (error) {
      console.log("Failed to handle tel protocol handler")
      console.warn(error)
      history.push("/dashboard/call/phone/all")
    }
  }

  return (
    <div className={classes.root}>
      <HelpMenu />
      <nav className={classes.drawer} aria-label="call bar">
        <Hidden mdUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div
              className={
                mobileOpen
                  ? clsx(classes.menuOpen, classes.menuButton)
                  : clsx(classes.menuClosed, classes.menuButton)
              }
            >
              <IconButton
                aria-label="open menu"
                onClick={handleDrawerToggle}
                className={classes.menuButtonIcon}
              >
                {mobileOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            {drawerMenu()}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawerMenu()}
          </Drawer>
        </Hidden>
      </nav>
      <Switch history={history} className={classes.content}>
        <Route path={`/dashboard/call/phone/:phoneTab`}>
          <Phone
            title="Phone"
            voip={voip}
            sipCalling={sipCalling}
            calling={calling}
            setSipCalling={setSipCalling} 
            setCalling={setCalling}
            sipMode={sipMode}
            options={options}
            value={value}
            setValue={setValue}
            viewValue={viewValue}
            setViewValue={setViewValue}
            controller={controller}
            handleSipDialClick={handleSipDialClick}
            handleDialClick={handleDialClick}
            deviceSelection={deviceSelection}
            setDeviceSelection={setDeviceSelection}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </Route>
        <Route path={`/dashboard/call/journal/:journalTab`}>
          <CallJournal
            title="Call Journal"
            menu={true}
            tab={tab}
            menuItems={journalMenu}
            handleDialClick={voip.active === 'yes' ? handleSipDialClick : handleDialClick}
            setCalling={voip.active === 'yes' ? setSipCalling : setCalling}
            setValue={setValue}
          />
        </Route>
        <Route path={`/dashboard/call/recording/:recordingTab`}>
          <Recording
            title="Call Recordings"
            menu={true}
            tab={tab}
            menuItems={recordingMenu}
            handleDialClick={voip.active === 'yes' ? handleSipDialClick : handleDialClick}
            setValue={setValue}
          />
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    phonebook: state.phonebook,
    startConference: state.user.startConference,
    voip: state.user.voip,
    remoteNumber: state.user.remoteNumber,
    callingNumber: state.user.callingNumber,
    calling: state.user.calling,
    sipCalling: state.user.sipCalling,
    autoDial: state.user.webapp.features.clickToDial.autoDial,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CallView)
);
